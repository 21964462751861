@font-face {
    font-family: 'Futura PT';
    src: url('/fonts/FuturaPT/FuturaPT-Bold.eot');
    src: local('Futura PT Bold'), local('FuturaPT-Bold'),
        url('/fonts/FuturaPT/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-Bold.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-Bold.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/fonts/FuturaPT/FuturaPT-Heavy.eot');
    src: local('Futura PT Heavy'), local('FuturaPT-Heavy'),
        url('/fonts/FuturaPT/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-Heavy.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-Heavy.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('/fonts/FuturaPT/FuturaPT-DemiObl.eot');
    src: local('Futura PT Demi Oblique'), local('FuturaPT-DemiObl'),
        url('/fonts/FuturaPT/FuturaPT-DemiObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-DemiObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-DemiObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-DemiObl.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('/fonts/FuturaPT/FuturaPTCond-ExtraBoldObl.eot');
    src: local('Futura PT Cond Extra Bold Oblique'), local('FuturaPTCond-ExtraBoldObl'),
        url('/fonts/FuturaPT/FuturaPTCond-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPTCond-ExtraBoldObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPTCond-ExtraBoldObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPTCond-ExtraBoldObl.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('/fonts/FuturaPT/FuturaPTCond-Book.eot');
    src: local('Futura PT Cond Book'), local('FuturaPTCond-Book'),
        url('/fonts/FuturaPT/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPTCond-Book.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPTCond-Book.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPTCond-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/fonts/FuturaPT/FuturaPT-LightObl.eot');
    src: local('Futura PT Light Oblique'), local('FuturaPT-LightObl'),
        url('/fonts/FuturaPT/FuturaPT-LightObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-LightObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-LightObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-LightObl.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('/fonts/FuturaPT/FuturaPT-BookObl.eot');
    src: local('Futura PT Book Oblique'), local('FuturaPT-BookObl'),
        url('/fonts/FuturaPT/FuturaPT-BookObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-BookObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-BookObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-BookObl.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/fonts/FuturaPT/FuturaPT-HeavyObl.eot');
    src: local('Futura PT Heavy Oblique'), local('FuturaPT-HeavyObl'),
        url('/fonts/FuturaPT/FuturaPT-HeavyObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-HeavyObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-HeavyObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-HeavyObl.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('/fonts/FuturaPT/FuturaPTCond-BoldObl.eot');
    src: local('Futura PT Cond Bold Oblique'), local('FuturaPTCond-BoldObl'),
        url('/fonts/FuturaPT/FuturaPTCond-BoldObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPTCond-BoldObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPTCond-BoldObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPTCond-BoldObl.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('/fonts/FuturaPT/FuturaPT-Demi.eot');
    src: local('Futura PT Demi'), local('FuturaPT-Demi'),
        url('/fonts/FuturaPT/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-Demi.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-Demi.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('/fonts/FuturaPT/FuturaPTCond-BookObl.eot');
    src: local('Futura PT Cond Book Oblique'), local('FuturaPTCond-BookObl'),
        url('/fonts/FuturaPT/FuturaPTCond-BookObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPTCond-BookObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPTCond-BookObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPTCond-BookObl.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('/fonts/FuturaPT/FuturaPT-ExtraBold.eot');
    src: local('Futura PT Extra Bold'), local('FuturaPT-ExtraBold'),
        url('/fonts/FuturaPT/FuturaPT-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-ExtraBold.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-ExtraBold.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('/fonts/FuturaPT/FuturaPTCond-Medium.eot');
    src: local('Futura PT Cond Medium'), local('FuturaPTCond-Medium'),
        url('/fonts/FuturaPT/FuturaPTCond-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPTCond-Medium.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPTCond-Medium.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPTCond-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/fonts/FuturaPT/FuturaPT-Medium.eot');
    src: local('Futura PT Medium'), local('FuturaPT-Medium'),
        url('/fonts/FuturaPT/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-Medium.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-Medium.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('/fonts/FuturaPT/FuturaPTCond-ExtraBold.eot');
    src: local('Futura PT Cond Extra Bold'), local('FuturaPTCond-ExtraBold'),
        url('/fonts/FuturaPT/FuturaPTCond-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPTCond-ExtraBold.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPTCond-ExtraBold.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPTCond-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/fonts/FuturaPT/FuturaPT-MediumObl.eot');
    src: local('Futura PT Medium Oblique'), local('FuturaPT-MediumObl'),
        url('/fonts/FuturaPT/FuturaPT-MediumObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-MediumObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-MediumObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-MediumObl.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('/fonts/FuturaPT/FuturaPTCond-Bold.eot');
    src: local('Futura PT Cond Bold'), local('FuturaPTCond-Bold'),
        url('/fonts/FuturaPT/FuturaPTCond-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPTCond-Bold.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPTCond-Bold.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPTCond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/fonts/FuturaPT/');
    src: local('Futura PT Bold Oblique'), local('FuturaPT-BoldObl'),
        url('/fonts/FuturaPT/?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-BoldObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-BoldObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-BoldObl.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('/fonts/FuturaPT/FuturaPT-Book.eot');
    src: local('Futura PT Book'), local('FuturaPT-Book'),
        url('/fonts/FuturaPT/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-Book.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-Book.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('/fonts/FuturaPT/FuturaPT-Light.eot');
    src: local('Futura PT Light'), local('FuturaPT-Light'),
        url('/fonts/FuturaPT/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-Light.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-Light.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('/fonts/FuturaPT/FuturaPTCond-MediumObl.eot');
    src: local('Futura PT Cond Medium Oblique'), local('FuturaPTCond-MediumObl'),
        url('/fonts/FuturaPT/FuturaPTCond-MediumObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPTCond-MediumObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPTCond-MediumObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPTCond-MediumObl.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('/fonts/FuturaPT/FuturaPT-ExtraBoldObl.eot');
    src: local('Futura PT Extra Bold Oblique'), local('FuturaPT-ExtraBoldObl'),
        url('/fonts/FuturaPT/FuturaPT-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FuturaPT/FuturaPT-ExtraBoldObl.woff2') format('woff2'),
        url('/fonts/FuturaPT/FuturaPT-ExtraBoldObl.woff') format('woff'),
        url('/fonts/FuturaPT/FuturaPT-ExtraBoldObl.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}




            /* ////////////////////////////////////////////////////////////////*/
                                /* Temporary rubik font */
            /* ////////////////////////////////////////////////////////////////*/





/* rubik-300 - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-regular - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500 - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-600 - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700 - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-800 - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-900 - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-italic - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-300italic - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-300italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500italic - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-500italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700italic - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-700italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-600italic - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-600italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-900italic - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-900italic.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-800italic - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/Rubik/rubik-v19-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Rubik/rubik-v19-latin-ext_latin-800italic.svg#Rubik') format('svg'); /* Legacy iOS */
}