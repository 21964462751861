@import "~antd/dist/antd.less";

@typography-title-font-weight: 500;
@font-size-base: 16px;
@black: #222222;

@table-header-bg: #E0E5F9;
@table-header-color: #222222;
@table-header-sort-bg: #E0E5F9;

@pagination-item-size: 40px;
@pagination-font-family:"Futura PT Demi", sans-serif;

@primary-color: #002CCC;

@popover-color: #767C93;